import React from 'react';

const InternalSuccessPage = ({ message }) => {
  return (
    <div style={{ width: '450px', height: '595px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#d4edda', color: '#155724', border: '1px solid #c3e6cb', borderRadius: '4px', textAlign: 'center' }}>
      <p>{message}</p>
    </div>
  );
};

export default InternalSuccessPage;
