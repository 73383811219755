import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {serverURL} from './constants'

const StepOneDCC = () => {
    const [searchParams] = useSearchParams();
        const [DDC_COLLECT_3D_CARDNINAL_COMMERCE_URL, setDCCCollect] = useState('');
        const [BIN, setBin] = useState('');
        const [JWT, setJwt] = useState('');
        
        useEffect(() => {
    const fetchParameters = async () => {
        const ddc_link = searchParams.get('ddc_link');
        const jwtToken = searchParams.get('jwt');
        try {
          const response = await fetch(serverURL +  'stepOneCall?ddc_link=' + encodeURIComponent(ddc_link) + "&jwt=" + encodeURIComponent(jwtToken));
          if (!response.ok) {
            throw new Error('Failed to fetch parameters');
          }
          const data = await response.json();
          // Extract parameters from API response
          const {
            DDC_COLLECT_3D_CARDNINAL_COMMERCE_URL,
            BIN,
            JWT
          } = data;
  
          // Set state with fetched parameters
          setDCCCollect(DDC_COLLECT_3D_CARDNINAL_COMMERCE_URL);
          setBin(BIN);
          setJwt(JWT);
        } catch (error) {
        }
      };
      fetchParameters()
}, []);

useEffect(() => {
    // Set the cookie
    document.cookie = 'w-pay=; path=/'; 

    // Perform actions when the component mounts
    const submitForm = () => {
      setTimeout(() => {
        const collectionForm = document.getElementById('collectionForm');
        if (collectionForm) {
          collectionForm.submit(); // Submit the form
        }
      }, 500);
    };

    // Call submitForm after component is mounted
    submitForm();

    // Cleanup (optional)
    return () => {
      // Optionally perform cleanup actions if needed
    };
  }, [DDC_COLLECT_3D_CARDNINAL_COMMERCE_URL]);
  
  return (
    <form
      id="collectionForm"
      name="devicedata"
      method="POST"
      action={DDC_COLLECT_3D_CARDNINAL_COMMERCE_URL}>
      <input type="hidden" name="Bin" value={`${BIN}`} />
      <input type="hidden" name="JWT" value={`${JWT}`} />
    </form>
  );
};

export default StepOneDCC;