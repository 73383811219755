import React, { useEffect, useState } from 'react';
import { RingLoader } from 'react-spinners';
import { useSearchParams } from 'react-router-dom';
import {serverURL} from './constants'
import ErrorPage from './ErrorPage';

const PaymentForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hash, setSessionId] = useState('');
  const [DDC_PARAMS, setDCCParams] = useState('');
  const [REDIRECT_PAGE, setRedirectPage] = useState('');
  const [CARDNINAL_COMMERCE_ORIGIN, setCardinalCommerceOrigin] = useState('');
  const [IS_FRAUD_SITE_ENGBLED, setIsFraudSiteEnabled] = useState('');
  const [PROFILING_DOMAIN, setProfilingDomain] = useState('');
  const [ORGANIZATION_UNIT, setOrganizationUnit] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchParameters = async () => {
      const code = searchParams.get('hash');
      try {
        const response = await fetch(serverURL + 'secure3DStart?hash=' + encodeURIComponent(code));
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        const data = await response.json();
        // Extract parameters from API response
        const {
          DDC_PARAMS,
          REDIRECT_PAGE,
          CARDNINAL_COMMERCE_ORIGIN,
          IS_FRAUD_SITE_ENGBLED,
          PROFILING_DOMAIN,
          ORGNIZATION_UNIT,
          hash,
        } = data;

        // Set state with fetched parameters
        setSessionId(code);
        setDCCParams(DDC_PARAMS);
        setRedirectPage(REDIRECT_PAGE);
        setCardinalCommerceOrigin(CARDNINAL_COMMERCE_ORIGIN);
        setIsFraudSiteEnabled(IS_FRAUD_SITE_ENGBLED);
        setProfilingDomain(PROFILING_DOMAIN);
        setOrganizationUnit(ORGANIZATION_UNIT);

        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchParameters();
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === window.origin && event.data.hello) return;
      
      if (event.data?.status === 'Payment-Authorised') {
        
        try {
          window.parent.postMessage({ source: 'gateway-payment', status: 'AUTHORISED' }, '*');
        } catch (ignore) {}
        window.location.href = `${REDIRECT_PAGE}?status=AUTHORISED`;
      }

      if (event.origin === CARDNINAL_COMMERCE_ORIGIN) {
        const data = JSON.parse(event.data);
        if (data !== undefined && data.Status === true) {
          console.log("SOME EVENT RECEIVED!!!!", data);
          var msgType = data.MessageType
          var sessionId = data.SessionId
          var status = data.Status
          setTimeout(() => {
            const iframe = document.getElementById('mainIframe');
            const ssid = localStorage.getItem('ses');
            iframe.src = `/stepOneDccCallsBackPage?hash=${encodeURIComponent(hash)}&sessionId=${sessionId}&ssid=${ssid}&msgType=${msgType}&status=${status}`;
            iframe.style.border='none'
          }, 300);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [REDIRECT_PAGE, CARDNINAL_COMMERCE_ORIGIN, hash]);

  useEffect(() => {
    if (IS_FRAUD_SITE_ENGBLED === 'true') {
      performJsc();
    }
  }, [IS_FRAUD_SITE_ENGBLED]);

  const performJsc = () => {
    const ndownc = createUUID();
    localStorage.setItem('ses', ndownc);
    setSessionId(ndownc);
  };

  const createUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  if (isLoading) {
    return (
      <div style={{ width: '480', height: '600', display: 'flex', justifyContent: 'left', top: '50px' }}>
        <RingLoader color="#3498db" loading={isLoading} size={40} />
      </div>
    );
  }

  if (error) {
    return  <ErrorPage message={error} />;
  }

  return (
    <div style={{ width: '455px', height: '600px', position: 'relative' }} id="payment-form-test">
      <iframe
        width="455"
        height="600"
        src={`/stepOneDcc?${DDC_PARAMS}`}
        id="mainIframe"
        style={{ position: 'absolute', border:'none' }}
        title='stepOne'
      ></iframe>

      <input type="hidden" id="sessionId" name="sessionId" value={hash} />
    </div>
  );
};

export default PaymentForm;