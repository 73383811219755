import React from "react";
import Card from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import "./style.css";

const DebitCard = ({ values, focused }) => {
  return (
    <Card
      cvc={values?.cvv}
      expiry={values?.month + "/" + values?.year}
      focused={focused}
      name={values?.cardname}
      number={values?.cardnumber}
    />
  );
};

export default DebitCard;
