export const googlePay_merchantInfo = {
  merchantId3D: process.env.REACT_APP_GOOGLE_MERCHANT_ID_3D,
  merchantName3D: process.env.REACT_APP_GOOGLE_MERCHANT_NAME_3D,
  merchantId2D: process.env.REACT_APP_GOOGLE_MERCHANT_ID_2D,
  merchantName2D: process.env.REACT_APP_GOOGLE_MERCHANT_NAME_2D,
  gatewayMerchantId2D: process.env.REACT_APP_GATEWAY_MERCHANT_ID_2D,
  gatewayMerchantId3D: process.env.REACT_APP_GATEWAY_MERCHANT_ID_3D,
  environment: process.env.REACT_APP_GOOGLE_ENVIRONMENT,
};

export const applePay_info = {
  merchantIdentifier2d: process.env.REACT_APP_APPLE_MERCHANT_IDENTIFIER_2D,
  merchantIdentifier3d: process.env.REACT_APP_APPLE_MERCHANT_IDENTIFIER_3D,
};
