import React, { useEffect, useRef } from "react";
import { googlePay_merchantInfo } from "../config";

export function GooglePay({
  values,
  isLoading,
  featureData,
  setIsLoading,
  is_google_pay,
  isGoogleApplePay,
  handleGooglePayClick,
}) {
  const googlePayClientRef = useRef(null);

  useEffect(() => {
    const loadGooglePayScript = () => {
      return new Promise((resolve, reject) => {
        if (document.getElementById("google-pay-script")) {
          resolve();
          return;
        }
        const script = document.createElement("script");
        script.id = "google-pay-script";
        script.src = "https://pay.google.com/gp/p/js/pay.js";
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    const loadGooglePay = async () => {
      await loadGooglePayScript();
      const googlePayClient = new window.google.payments.api.PaymentsClient({
        environment: googlePay_merchantInfo.environment,
      });
      googlePayClientRef.current = googlePayClient;
    };

    // Ensure the context is secure
    if (window.isSecureContext) {
      loadGooglePay().catch((err) => {
        console.error("Failed to load Google Pay script", err);
      });
    } else {
      console.error("Google Pay requires a secure context (HTTPS).");
    }
  }, []);

  const handleCustomButtonClick = async () => {
    if (!googlePayClientRef.current) {
      console.error("Google Pay client not initialized");
      return;
    }

    const paymentRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: "CARD",
          parameters: {
            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
            allowedCardNetworks: [
              "AMEX",
              "DISCOVER",
              "INTERAC",
              "JCB",
              "MASTERCARD",
              "VISA",
            ],
          },
          tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
              gateway: "worldpay",
              gatewayMerchantId: featureData?.is_support_3d
                ? googlePay_merchantInfo.gatewayMerchantId3D
                : googlePay_merchantInfo.gatewayMerchantId2D,
            },
          },
        },
      ],
      merchantInfo: {
        merchantId: featureData?.is_support_3d
          ? googlePay_merchantInfo.merchantId3D
          : googlePay_merchantInfo.merchantId2D,
        merchantName: featureData?.is_support_3d
          ? googlePay_merchantInfo.merchantId3D
          : googlePay_merchantInfo.merchantId2D,
      },
      transactionInfo: {
        totalPriceStatus: "FINAL",
        totalPriceLabel: "Total",
        totalPrice: featureData?.amount + "",
        currencyCode: featureData?.currency,
        countryCode: featureData?.country_code|| 'US',
      },
    };

    try {
      setIsLoading(true);
      const paymentData = await googlePayClientRef.current.loadPaymentData(
        paymentRequest
      );

      const res = JSON.parse(
        paymentData.paymentMethodData.tokenizationData.token
      );
      const temp = {
        ...values,
        payment_method: "googlepay",
        google_pay_response: {
          protocol_version: res.protocolVersion,
          signature: res.signature,
          signed_message: res.signedMessage,
        },
      };
      handleGooglePayClick(false, temp);
    } catch (err) {
      console.error("Google Pay error", err);
      setIsLoading(false);
    }
  };

  const handleClick = () => {
    if (!isGoogleApplePay) {
      // if (Object.values(values).includes("")) {
      //   handleGooglePayClick?.(false);
      // } else {
        handleCustomButtonClick();
      // }
    } else {
      handleCustomButtonClick();
    }
  };
  return (
    <>
      <button
        type="button"
        className="google-pay-button"
        onClick={handleClick}
        disabled={!is_google_pay || isLoading}
        style={{ pointerEvents: is_google_pay && !isLoading ? "auto" : "none" }}
      >
        {isLoading ? (
          "loading..."
        ) : (
          <>
            <svg
              viewBox="0 0 488 512"
              xmlns="http://www.w3.org/2000/svg"
              className="main-header__logo-image"
            >
              <path
                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                fill="#ffffff"
              />
            </svg>{" "}
            <span style={{ marginLeft: "3px" }}> Pay</span>
          </>
        )}
      </button>
    </>
  );
}
