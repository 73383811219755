import React from "react";
import Logo from "../../assets/svgs/Logo";

const Header = () => {
  return (
    <div className="header">
      <Logo />
      <h1>Gateway Guardian - Secure Payments</h1>
      <img
        src={process.env.PUBLIC_URL + "/assets/pngs/visa-mastercard.png"}
        alt="masterCard"
      />
    </div>
  );
};

export default Header;
