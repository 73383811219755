// src/components/layout/Layout.js
import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Loader from "./Loader";
import { useLoading } from "../../context/LoadingContext";

const Layout = ({ children }) => {
  const { isLoading } = useLoading();
  const location = useLocation();
  const noHeaderRoutes = ["/stepOneDcc"];

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <main>
          {!noHeaderRoutes.includes(location.pathname) && <Header />}
          <section>{children}</section>
        </main>
      )}
    </div>
  );
};

export default Layout;
