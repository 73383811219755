import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {serverURL} from './constants'
import InternalSuccessPage from './InternalSuccessPage'
import ErrorPage from './ErrorPage';

const StepOneDCC = () => {
    const [searchParams] = useSearchParams();
    const [link, setLink] = useState('');
    const [jwt, setJwt] = useState('');
    const [hashed, setHash] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
        
        useEffect(() => {
          
    const fetchParameters = async () => {
      console.log("RUNNING PAGE 1")
        const hash = searchParams.get('hash');
        const sessionId = searchParams.get('sessionId');
        const messageType = searchParams.get('msgType');
        const status = searchParams.get('status');
        try {
          const response = await fetch(serverURL + 'stepOneCallBack?hash=' 
          + encodeURIComponent(hash) 
          + "&sessionId=" + encodeURIComponent(sessionId)
          + "&msgType=" + encodeURIComponent(messageType)
          + "&status=" + encodeURIComponent(status));
          if (!response.ok) {
            console.log(response.statusText)
            throw new Error(response.statusText);
          }
          const data = await response.json();
          if (data.status == "Failed") {
            throw new Error(data.message);
          }

          if (data.status == "Success") {
            setSuccess(data.message)
          }

          if (data.status == "Challenge") {
          setLink(data.DDC_CHANLANGE_CARDNINAL_COMMERCE_URL)
          setHash(data.hash)
          setJwt(data.JWT)
          }
        } catch (error) {
          setError(error.message);
        }
      };
      fetchParameters()
}, []);
  
useEffect(() => {
    const submitForm = () => {
        document.getElementById('challengeForm2').submit();
      };
      if(link != ""){
        submitForm()
        console.log("submitting form...")
      }
      
    //   const timer = setTimeout(submitForm, 500);
  
    //   return () => clearTimeout(timer); 
}, [link, jwt]);

if(error){
  return  <ErrorPage message={error} />;
}

if(success){
  return  <InternalSuccessPage message={success} />;
}

  return (
    <div>
        <form id="challengeForm2" method="POST" action={link}>
      <input type="hidden" name="JWT" value={jwt} />
      <input type="hidden" name="MD" value={hashed} />
    </form>
    </div>
  );
};

export default StepOneDCC;