import React from 'react';

const ErrorPage = ({ message }) => {
  return (
    <div style={{ width: '450px', height: '595px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f8d7da', color: '#721c24', border: '1px solid #f5c6cb', borderRadius: '4px' }}>
      <p>{message}</p>
    </div>
  );
};

export default ErrorPage;
