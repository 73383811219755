import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const FailPage = () => {
  const [searchParams] = useSearchParams();
  const [showUserDetails, setShowUserDetails] = useState(true);
  const [params, setParams] = useState({
    failMessage: "",
    cardHolderName: "",
    cardHolderNumber: "",
    cardAmount: "",
    cardCurrency: "",
  });

  useEffect(() => {
    if (searchParams.get("noUser")) {
      setShowUserDetails(false);
    }
    setParams({
      failMessage: searchParams.get("failMessage"),
      cardHolderName: searchParams.get("cardHolderName"),
      cardHolderNumber: searchParams.get("cardHolderNumber"),
      cardAmount: searchParams.get("cardAmount"),
      cardCurrency: searchParams.get("cardCurrency"),
    });
  }, [searchParams]);

  return (
    // keys:
    // successMessage, cardHolderName, cardHolderNumber, cardAmount, cardCurrency
    <div className="container message">
      <img
        src={`${process.env.PUBLIC_URL}/assets/pngs/error.png`}
        alt="Error"
      />
      {showUserDetails && (
        <h1>
          Transaction of {params.cardAmount} {params.cardCurrency} by
          {params.cardHolderName} ({params.cardHolderNumber}) was not
          successful.
        </h1>
      )}
      <p>Reason: {params.failMessage}</p>
    </div>
  );
};

export default FailPage;
