// Logo.js
import React from "react";

const Logo = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 20.8334C6 14.4381 6 11.2406 6.75504 10.1648C7.51006 9.08908 10.5166 8.05992 16.5298 6.00158L17.6754 5.60944C20.81 4.53648 22.3772 4 24 4C25.6228 4 27.19 4.53648 30.3246 5.60944L31.4702 6.00158C37.4834 8.05992 40.49 9.08908 41.245 10.1648C42 11.2406 42 14.4381 42 20.8334C42 21.7992 42 22.8468 42 23.9828C42 35.2588 33.522 40.731 28.2028 43.0546C26.76 43.6848 26.0386 44 24 44C21.9614 44 21.24 43.6848 19.7971 43.0546C14.4779 40.731 6 35.2588 6 23.9828C6 22.8468 6 21.7992 6 20.8334Z"
      fill="#166FF5"
      fillOpacity="0.91"
    />
    <rect x="11" y="11" width="26" height="26" rx="13" fill="#1870F5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.3707 20.3072C32.8731 20.274 30.3805 20.2407 27.8928 20.2073C29.3747 18.6526 30.9003 17.1045 32.4695 15.563C32.5029 15.5297 32.5029 15.4965 32.4695 15.4632C31.1938 14.2307 29.7182 13.3068 28.0428 12.6916C23.6092 11.2721 19.6077 12.0378 16.0381 14.9887C12.4357 18.4221 11.2436 22.5586 12.4617 27.3985C14.026 32.0563 17.2189 34.8779 22.0405 35.8631C26.3203 36.442 29.9467 35.1852 32.9197 32.0927C35.8664 28.5742 36.7052 24.7123 35.4091 20.3072L35.3707 20.3072ZM22.0405 25.9503L22.1405 25.9003L27.7927 20.2073C26.5856 19.0369 25.1351 18.5126 23.441 18.6343C21.1211 19.0032 19.5788 20.2933 18.8142 22.5045C18.3981 24.1751 18.6648 25.7231 19.6145 27.1488C20.8945 28.8127 22.5868 29.5369 24.6915 29.3211C26.701 29.018 28.1183 27.9444 28.9431 26.1001C26.6338 26.0668 24.3162 26.0335 21.9904 26.0002C21.9966 25.9699 22.0133 25.9533 22.0405 25.9503Z"
      fill="white"
    />
  </svg>
);

export default Logo;
