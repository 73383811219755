import * as Yup from "yup";

const paymentCondition = (isGooglePay, isApplePay) =>
  !isGooglePay && !isApplePay;

export const cardPaymentSchema = Yup.object().shape({
  is_google_pay: Yup.boolean().default(false),
  is_apple_pay: Yup.boolean().default(false),
  cardname: Yup.string().when(["is_google_pay", "is_apple_pay"], {
    is: paymentCondition,
    then: () => Yup.string().required("Card name is required"),
    otherwise: () => Yup.string().nullable(),
  }),
  cardnumber: Yup.string().when(["is_google_pay", "is_apple_pay"], {
    is: paymentCondition,
    then: () =>
      Yup.string()
        .required("Card number is required")
        .matches(
          /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9]{2})[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
          "Invalid card number"
        ),
    otherwise: () => Yup.string().nullable(),
  }),
  month: Yup.string().when(["is_google_pay", "is_apple_pay"], {
    is: paymentCondition,
    then: () =>
      Yup.string()
        .required("Expiry month is required")
        .matches(/^\d*$/, "Invalid"),
    otherwise: () => Yup.string().nullable(),
  }),
  year: Yup.string().when(["is_google_pay", "is_apple_pay"], {
    is: paymentCondition,
    then: () =>
      Yup.string()
        .required("Expiry year is required")
        .matches(/^\d*$/, "Invalid"),
    otherwise: () => Yup.string().nullable(),
  }),
  cvv: Yup.string().when(["is_google_pay", "is_apple_pay"], {
    is: paymentCondition,
    then: () =>
      Yup.string().required("CVC is required").matches(/^\d*$/, "Invalid"),
    otherwise: () => Yup.string().nullable(),
  }),
  email: Yup.string().when(["is_google_pay", "is_apple_pay"], {
    is: paymentCondition,
    then: () =>
      Yup.string().required("Email is required").email("Enter a valid email"),
    otherwise: () => Yup.string(),
  }),
  phone: Yup.string().when(["is_google_pay", "is_apple_pay"], {
    is: paymentCondition,
    then: () => Yup.string().required("Phone is required"),
    otherwise: () => Yup.string(),
  }),
  country: Yup.string().when(["is_google_pay", "is_apple_pay"], {
    is: paymentCondition,
    then: () => Yup.string().required("Country is required"),
    otherwise: () => Yup.string(),
  }),
  city: Yup.string().when(["is_google_pay", "is_apple_pay"], {
    is: paymentCondition,
    then: () => Yup.string().required("City is required"),
    otherwise: () => Yup.string(),
  }),
  state: Yup.string().when(["is_google_pay", "is_apple_pay"], {
    is: paymentCondition,
    then: () => Yup.string().required("State is required"),
    otherwise: () => Yup.string(),
  }),
  postalcode: Yup.string().when(["is_google_pay", "is_apple_pay"], {
    is: paymentCondition,
    then: () => Yup.string().required("Postal Code is required"),
    otherwise: () => Yup.string(),
  }),
  address: Yup.string().when(["is_google_pay", "is_apple_pay"], {
    is: paymentCondition,
    then: () => Yup.string().required("Address is required"),
    otherwise: () => Yup.string(),
  }),
});
