import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const SuccessPage = () => {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState({
    successMessage: "",
    cardHolderName: "",
    cardHolderNumber: "",
    cardAmount: "",
    cardCurrency: "",
  });

  useEffect(() => {
    setParams({
      successMessage: searchParams.get("successMessage"),
      cardHolderName: searchParams.get("cardHolderName"),
      cardHolderNumber: searchParams.get("cardHolderNumber"),
      cardAmount: searchParams.get("cardAmount"),
      cardCurrency: searchParams.get("cardCurrency"),
    });
  }, [searchParams]);

  return (
    <div className="container message">
      <img
        src={`${process.env.PUBLIC_URL}/assets/pngs/success.png`}
        alt="Success"
      />
      <h1>
        Transaction of {params.cardAmount} {params.cardCurrency} by
        {params.cardHolderName} ({params.cardHolderNumber}) was successful.
      </h1>
      <p>Reason: {params.successMessage}</p>
    </div>
  );
};

export default SuccessPage;
