// src/App.js

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/HomePage";
import ThreeDSecure from "./components/ThreeDSecure";
import StepOneDcc from "./components/StepOneDCC";
import StepOneDccCallsBack from "./components/StepOneDccCallsBackPage";
import SuccessPage from "./components/SuccessPage";
import FailPage from "./components/FailPage";
import CheckoutForm from "./components/checkout/CheckoutForm";
import Invoice from "./components/invoice/Invoice";
import Layout from "./components/layout";
import { LoadingProvider } from "./context/LoadingContext";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "./App.css";
import { RECAPTCHA_SITE_KEY } from "./components/constants";
import { GglApplePay } from "./components/google-apple-pay";

function App() {
  return (
    <LoadingProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/3dSecure" element={<ThreeDSecure />} />
          <Route path="/stepOneDcc" element={<StepOneDcc />} />
          <Route
            path="/stepOneDccCallsBackPage"
            element={<StepOneDccCallsBack />}
          />
          <Route path="/successPage" element={<SuccessPage />} />
          <Route path="/failPage" element={<FailPage />} />
          <Route
            path="/ct3U6F8"
            element={
              <Layout>
                <CheckoutForm />
              </Layout>
            }
          />
          <Route
            path="/in45V3E"
            element={
              <Layout>
                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                  <Invoice />
                </GoogleReCaptchaProvider>
              </Layout>
            }
          />
          <Route
            path="/mp3U6F8"
            element={
              <Layout>
                <GglApplePay />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </LoadingProvider>
  );
}

export default App;
