/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DebitCard from "../../debitCard/DebitCard";
import { countryList } from "../../constants/countries";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { invoicePaymentSchema } from "./helper";
import { errorNotification } from "../../notification-popup";
import { useSearchParams } from "react-router-dom";
import { api_checkout_invoice_url } from "../constants";
import "../checkout/style.css";
import { GooglePay } from "../google-pay";
import { ApplePay } from "../apple-pay";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Invoice = () => {
  const [focused, setFocused] = useState("");
  const [featureData, setFeatureData] = useState({
    amount: "",
    currency: "",
    is_apple_pay: false,
    is_google_pay: false,
    is_support_3d: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGooglePaying, setIsGooglePaying] = useState(false);
  const [isApplePaying, setIsApplePaying] = useState(false);
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(invoicePaymentSchema),
    defaultValues: {
      cardname: "",
      cardnumber: "",
      month: "",
      year: "",
      cvv: "",
      is_google_pay: false,
      is_apple_pay: false,
    },
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFocused = (e) => {
    setFocused(e.target.name === "cvv" ? "cvc" : e.target.name);
  };

  // Handle input change
  const handleChange = (e, name, limit) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^\d]/g, "");
    if (
      (name === "month" || name === "year") &&
      sanitizedValue.length <= limit
    ) {
      e.target.value = sanitizedValue;
      setValue(name, value);
      return;
    }
    if (name === "cvc" && sanitizedValue.length <= limit) {
      e.target.value = sanitizedValue;
      setValue("cvv", value);
      return;
    } else {
      e.target.value = sanitizedValue.slice(0, limit);
    }
  };

  const onSubmit = async (values) => {
    if (!isSubmitting) {
      if (!executeRecaptcha) {
        errorNotification("reCAPTCHA not initialized");
        return;
      }

      const captchaToken = await executeRecaptcha("submit");

      if (!captchaToken) {
        errorNotification("Failed to get reCAPTCHA token");
        return;
      }

      delete values["is_apple_pay"];
      delete values["is_google_pay"];
      const apiUrl = api_checkout_invoice_url + "wpf/invoice";
      setIsSubmitting(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...values,
          captchaValue: captchaToken,
          i2n8e34v: searchParams.get("i2n8e34v"),
          payment_method: "card",
          is_support_3d: featureData.is_support_3d,
        }),
      };

      try {
        const response = await fetch(apiUrl, requestOptions);
        const data = await response.json();
        if (!response.ok) {
          setIsSubmitting(false);
          errorNotification(data?.message);
          throw new Error(response);
        }
        const d = data?.data || data;
        window.location.href = d.url;

        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
      }
    }
  };

  const handleCheckFeature = async () => {
    const apiUrl = api_checkout_invoice_url + "wpf/check-feature";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        i2n8e34v: searchParams.get("i2n8e34v"),
      }),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(response);
      }
      setFeatureData(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    handleCheckFeature();
  }, []);

  const handleGooglePay = async (isCallSubmit, body) => {
    setValue("is_google_pay", true);
    setValue("is_apple_pay", false);
    if (isCallSubmit) {
      handleSubmit(onSubmit)(); // Trigger form submission programmatically
    } else {
      if (!executeRecaptcha) {
        errorNotification("reCAPTCHA not initialized");
        return;
      }

      const captchaToken = await executeRecaptcha("submit");

      if (!captchaToken) {
        errorNotification("Failed to get reCAPTCHA token");
        return;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...body,
          captchaValue: captchaToken,
          i2n8e34v: searchParams.get("i2n8e34v"),
          is_support_3d: featureData.is_support_3d,
        }),
      };
      const apiUrl = api_checkout_invoice_url + "wpf/invoice";
      try {
        const response = await fetch(apiUrl, requestOptions);
        const data = await response.json();
        if (!response.ok) {
          setIsGooglePaying(false);
          errorNotification(data?.message);
          throw new Error(response);
        }
        const d = data?.data || data;
        window.location.href = d.url;

        setIsGooglePaying(false);
      } catch (error) {
        setIsGooglePaying(false);
      }
    }
  };

  const handleApplePay = async (isCallSubmit, body) => {
    setValue("is_google_pay", false);
    setValue("is_apple_pay", true);
    if (isCallSubmit) {
      handleSubmit(onSubmit)(); // Trigger form submission programmatically
    } else {
      if (!executeRecaptcha) {
        errorNotification("reCAPTCHA not initialized");
        return;
      }

      const captchaToken = await executeRecaptcha("submit");

      if (!captchaToken) {
        errorNotification("Failed to get reCAPTCHA token");
        return;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...body,
          captchaValue: captchaToken,
          i2n8e34v: searchParams.get("i2n8e34v"),
          is_support_3d: featureData.is_support_3d,
        }),
      };
      const apiUrl = api_checkout_invoice_url + "wpf/invoice";
      try {
        const response = await fetch(apiUrl, requestOptions);
        const data = await response.json();
        if (!response.ok) {
          setIsApplePaying(false);
          errorNotification(data?.message);
          throw new Error(response);
        }
        const d = data?.data || data;
        window.location.href = d.url;

        setIsApplePaying(false);
      } catch (error) {
        setIsApplePaying(false);
      }
    }
  };

  const values = watch();
  return (
    <div className="checkout-form">
      <form className="wrapper" onSubmit={handleSubmit(onSubmit)}>
        <div className="upper-wrapper">
          <DebitCard values={values} focused={focused} />
          <div className="fields-wrapper">
            <div className="input">
              <label htmlFor="cardName">
                <span>*</span>Card Name
              </label>
              <input
                name="cardname"
                type="text"
                id="cardName"
                placeholder="John Wick"
                {...register("cardname")}
                onFocus={handleFocused}
              />
              {errors?.cardname?.message && (
                <p className="error-field">{errors?.cardname?.message}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="cardNumber">
                <span>*</span>Card Number
              </label>
              <input
                name="cardnumber"
                type="text"
                id="cardNumber"
                placeholder="1234 1234 1234 1234"
                {...register("cardnumber", {
                  onChange: (e) => {
                    handleChange(e, "cardnumber");
                  },
                })}
                onFocus={handleFocused}
              />
              {errors?.cardnumber?.message && (
                <p className="error-field">{errors?.cardnumber?.message}</p>
              )}
            </div>
            <div className="flex-3">
              <div className="input">
                <label htmlFor="cardExpiryMonth">
                  <span>*</span>Expiry Month
                </label>
                <input
                  name="month"
                  type="text"
                  id="cardExpiryMonth"
                  placeholder="MM"
                  maxLength={"2"}
                  {...register("month", {
                    onChange: (e) => {
                      handleChange(e, "month", 2);
                    },
                  })}
                  onFocus={handleFocused}
                />
                {errors?.month?.message && (
                  <p className="error-field">{errors?.month?.message}</p>
                )}
              </div>
              <div className="input">
                <label htmlFor="cardExpiryYear">
                  <span>*</span>Expiry Year
                </label>
                <input
                  name="year"
                  type="text"
                  id="cardExpiryYear"
                  placeholder="YY"
                  maxLength={"2"}
                  {...register("year", {
                    onChange: (e) => {
                      handleChange(e, "year", 2);
                    },
                  })}
                  onFocus={handleFocused}
                />
                {errors?.year?.message && (
                  <p className="error-field">{errors?.year?.message}</p>
                )}
              </div>
              <div className="input">
                <label htmlFor="cardCvc">
                  <span>*</span>CVC
                </label>
                <input
                  name="cvc"
                  type="text"
                  id="cardCvc"
                  maxLength={"3"}
                  placeholder="123"
                  {...register("cvv", {
                    onChange: (e) => {
                      handleChange(e, "cvv", 3);
                    },
                  })}
                  onFocus={handleFocused}
                />
                {errors?.cvv?.message && (
                  <p className="error-field">{errors?.cvv?.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-wrapper">
          <div className="flex-2">
            <div className="input">
              <label htmlFor="email">Email</label>
              <input
                name="email"
                type="text"
                id="email"
                placeholder="abc@gmail.com"
                {...register("email")}
              />
              {errors?.email?.message && (
                <p className="error-field">{errors?.email?.message}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="phone">Phone</label>
              <input
                name="phone"
                type="tel"
                id="phone"
                placeholder="123123123"
                {...register("phone")}
              />
              {errors?.phone?.message && (
                <p className="error-field">{errors?.phone?.message}</p>
              )}
            </div>
          </div>
          <div className="flex-2">
            <div className="input">
              <label htmlFor="amount">Amount</label>
              <input
                name="state"
                type="text"
                id="amount"
                disabled={true}
                readOnly={true}
                value={featureData?.amount}
              />
            </div>
            <div className="input">
              <label htmlFor="country">Choose a country:</label>
              <select id="country" {...register("country")}>
                {/* Map over the countries array to create options */}
                <option value={""}>{"select"}</option>
                {countryList.map((country, index) => (
                  <option key={`country_${index + 1}`} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </select>
              {errors?.country?.message && (
                <p className="error-field">{errors?.country?.message}</p>
              )}
            </div>
          </div>
          <div className="flex-3">
            <div className="input">
              <label htmlFor="state">State</label>
              <input
                name="state"
                type="text"
                id="state"
                placeholder="State"
                {...register("state")}
              />
              {errors?.state?.message && (
                <p className="error-field">{errors?.state?.message}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="city">City</label>
              <input
                name="city"
                type="text"
                id="city"
                placeholder="City"
                {...register("city")}
              />
              {errors?.city?.message && (
                <p className="error-field">{errors?.city?.message}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="postalCode">Postal Code</label>
              <input
                name="postalcode"
                type="text"
                id="postalCode"
                placeholder="Postal Code"
                {...register("postalcode")}
              />
              {errors?.postalcode?.message && (
                <p className="error-field">{errors?.postalcode?.message}</p>
              )}
            </div>
          </div>
          <div className="input">
            <label htmlFor="address">Address</label>
            <input
              name="address"
              type="text"
              id="address"
              placeholder="42 wall street"
              {...register("address")}
            />
            {errors?.address?.message && (
              <p className="error-field">{errors?.address?.message}</p>
            )}
          </div>
        </div>
        <button
          className="checkoutBtn"
          disabled={isSubmitting}
          onClick={() => {
            setValue("is_google_pay", false);
            setValue("is_apple_pay", false);
          }}
          style={{ pointerEvents: isSubmitting ? "none" : "auto" }}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
        <div className="paymentMethodDiv">
          <GooglePay
            featureData={featureData}
            isLoading={isGooglePaying}
            setIsLoading={setIsGooglePaying}
            handleGooglePayClick={handleGooglePay}
            is_google_pay={featureData.is_google_pay}
            values={{}}
          />
          <ApplePay
            featureData={featureData}
            isLoading={isApplePaying}
            setIsLoading={setIsApplePaying}
            handleApplePayClick={handleApplePay}
            is_apple_pay={featureData.is_apple_pay}
            values={{}}
            is_support_3d={featureData.is_support_3d}
          />
        </div>
      </form>
    </div>
  );
};

export default Invoice;
